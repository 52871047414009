<template>
    <div class="d-flex content-center min-vh-100">
        <BContainer>
            <BRow class="justify-content-center">
                <BCol md="8">
                    <BCard class="text-left" footer-tag="footer" footer-bg-variant="light">
                        <div slot="header">
                            <strong>비밀번호 재설정</strong>
                            <div class="card-header-actions">
                                <small class="text-muted">비밀번호를 재설정 합니다.</small>
                            </div>
                        </div>


                        <BRow >
                            <BCol>
                                <BFormInput class="mt-3" v-model="newPwd"  :state="validPwdState"  type="password" placeholder="영문.숫자.특수문자 조합 최소8자 이상입력" :disabled="!isValidUser"/>
                                <BFormInput class="mt-2" v-model="newPwdConfirm" :state="validPwdConfirm" type="password" placeholder="비밀번호를 다시 입력해주새요" :disabled="!isValidUser"/>
                                <div class="text-right mt-3">
                                    <BButton  variant="danger" @click="setUserPwd" :disabled="!isValidUser || !isPwdChecked">비밀번호 변경</BButton>
                                </div>
                            </BCol>
                        </BRow>


                        <div slot="footer">

                            <div class="small text-muted">
                                <strong><i class="fa fa-copyright"></i> <a href='http://qqkorea.net'>(주)하우코스트</a></strong>
                                <span class="hidden-xs en">All rights reserved.</span><br>
                                <span class="hidden-xs en">
                                    대표자 : 유현오 | E-Mail : <a href="mailto:mycost@naver.com">mycost@naver.com</a>) <br>
                                    <a href='http://xcost.me/bbs/qalist.php'>적산문의/프로그램/문의 1:1상담</a> | tel. 031-417-0466 | fax. 02-6442-0466 <br/>
                                    사무실 : 경기도 수원시 권선구 수성로 8 경기중소기업성장지원센터 303호 <br/>부설연구소 : 건설계약연구소/벤쳐기업인증/연구개발서비스업등록/소프트웨어개발업/ISO9001,ISO14001인증<br/>
                                    사업자번호 : 809-86-00250</span>
                            </div>
                        </div>

                    </BCard>


                </BCol>
            </BRow>
        </BContainer>


    </div>
</template>

<script>
    import {
        apiCall,
        cloneVar,
        alertError,
        alertWarn,
        alertSuccess,
        alertConfirm,
        checkBisNo,
        commify,
        toastSync,
        warpSite,
    } from '../../../common/utils';

    import DaumPostcode from 'vuejs-daum-postcode';
    import moment from "moment";

    export default {
        name: 'PasswordReset',
        components: {
            DaumPostcode
        },
        props: ['memberToken'],
        data () {
            return {
                newPwd: '',
                newPwdConfirm: '',
                isValidUser: false,
                isPwdChecked: false,
                pwdRegexp: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/, // 최소8자 영문,숫자,특수문자 조합
                // pwdRegexp: /^(?=.*[0-9]+)[a-zA-Z][a-zA-Z0-9]{7,29}$/, // 영어+숫자(8자이상)
            }
        },
        async created(){


            console.log("-------- created: PasswordReset From ----- token : " + this.memberToken );
            try{
                const r = await apiCall('get', `/api/member/pwd-change/${this.memberToken}`);
                if(r.result){
                    this.isValidUser = true;
                }else{
                    await alertWarn( this.$bvModal, '링크가 만료되었거나 접근오류입니다', '정보 없음' );
                    return;
                }

                // window.location.href = 'http://xcost.me';

            }catch(err){
                console.log(err);
                await alertWarn( this.$bvModal, 'ERROR: '+err.message, '에러' );
                return;
            }
        },
        beforeMount() {

        },
        mounted(){
            console.log("-------- mounted: PasswordReset -----  " );

        },

        computed: {

            validPwdState(){
                let ret = false;
                // const p2 = this.newPwdConfirm;
                if( this.newPwd.length > 7 ){
                    if( this.pwdRegexp.test( this.newPwd )) ret = true;
                }
                return ret;
            },
            validPwdConfirm(){
                let ret = false;
                const p1 = this.newPwd;
                const p2 = this.newPwdConfirm;
                if( p2.length > 7 && p1 === p2 ){
                    ret = true;
                    this.isPwdChecked = true;
                }else{
                    this.isPwdChecked = false;
                }
                return ret;
            },
        },
        methods: {

            async setUserPwd(){
                console.log( "setUserPwd ---> ");
                try{
                    const param = {
                        memberToken : this.memberToken,
                        userPwd : this.newPwd
                    };
                    const r = await apiCall('post', `/api/member/pwd-change`, param);
                    if(r.result){
                        await alertSuccess(this.$bvModal, "비밀번호가 변경 되었습니다", "Success");
                        window.location.href = warpSite();
                    }else{
                        await alertWarn( this.$bvModal, '비밀번호 변경 실패: '+ r.message, '정보 없음' );
                        alert('관리자에게 문의하시기 바랍니다');
                    }

                }catch(err){
                    console.log( err );
                    await alertWarn( this.$bvModal, 'ERROR: ' + err.message, '에러' );
                }
            },

        }

    }
</script>
